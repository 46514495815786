    import React from 'react';
    import './component-css/Resources.css'

    const Resources = () => {
    return (
        <div className="resources-page">
            <h1>Explore our tutorials and resources below:</h1>
            <div className="video-container">
            <div className="video-item">
                <h3>Welcome to the Discourse 1Future Platform</h3>
                <video controls>
                <source src="/videos/WELCOME.mp4" type="video/mp4" />
                Your browser does not support the video tag.
                </video>
            </div>
            <div className="video-item">
                <h3>How to create an account?</h3>
                <video controls>
                <source src="/videos/Tutorial2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
                </video>
            </div>
            <div className="video-item">
                <h3>How to login and customize your profile?</h3>
                <video controls>
                <source src="/videos/Tutorial1.mp4" type="video/mp4" />
                Your browser does not support the video tag.
                </video>
            </div>
            <div className="video-item">
                <h3>Categories and groups</h3>
                <video controls>
                <source src="/videos/categories&groups.mp4" type="video/mp4" />
                Your browser does not support the video tag.
                </video>
            </div>
            </div>
        </div>
        );
    };

    export default Resources;
