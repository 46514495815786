import React from 'react';
import './component-css/Footer.css'; 

const Footer = () => {
  return (
    <footer className="footer">
        {/* Left section with Disclaimer and Founder Image */}
        <div className="disclaimer-section">
            <div className="disclaimer-image">
                <img src="/images/founded.png" alt="Additional Information" />
            </div>
            <p className="disclaimer">
                DISCLAIMER: Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or EACEA. Neither the European Union nor the granting authority can be held responsible for them.
            </p>
        </div>

        {/* Right section with Copyright */}
        <div className="footer-bottom">
            <p className="footer-bottom">&copy; 2024 1FUTURE. All Rights Reserved.</p>
        </div>

        {/* Social Icons (Optional, can be placed as needed) */}
        {/* You can add social icons here if needed */}
    </footer>
  );
};

export default Footer;
