import React from 'react';

const UnderConstruction = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <img src="/images/underconstruction2.png" alt="Descriptive Alt Text" style={{ maxWidth: '100%', height: 'auto', marginTop: '5%' }}
        />
        </div>
    );
};

export default UnderConstruction;
