import React from 'react';
import './component-css/AboutUs.css'; 

const AboutUs = () => {
return (
    <section className="about-us">
    <div className="container">
        <h3 className="title">
        Welcome to the 1FUTURE Platform: Where Business Meets Academia to Collaborate for a Greener Tomorrow</h3>
        <h2 className="intro">Who are we?</h2>
        <p >
        The 1FUTURE Platform has been created as a collaborative space in the Western Balkan Region, where businesses, students, academics, and other stakeholders can join and discuss different topics related to sustainability.
        </p>
        <p>
        The ultimate aim of the forum is to initiate concrete collaboration between parties, such as joint projects, internships, research activities, and more.
        The platform has been developed through the support of the EU-funded project <strong>jOiNEd For sUsTainability</strong> - Building Climate Resilient Communities in WB and EU - 1FUTURE Project (101082815-ERASMUS-EDU-2022-CBHE-STRAND-2).
        </p>
        <p>
        Through the platform, each user can create their own profile, stating their main interest in joining the forum and their expectations.
        The <strong>1FUTURE Platform</strong> offers the opportunity to engage in discussions on various topics, organized into categories.
        </p>
        <p>
        As users become more active on the platform, their activity will be recorded, and profile upgrades will be available to reflect their participation. A dedicated platform administrator is available to assist with questions and resolve issues. Additionally, several forum moderators have been appointed to manage different discussion topics, offering relevant insights and guidance.
        </p>
        <p>
        Finally, the platform provides open-source resources to all users, including learning materials and tutorials.
        </p>
        <p className="closing">
        Join us and enjoy the <strong>1FUTURE Platform</strong>!
        </p>
    </div>
    </section>
);
};

export default AboutUs;