import React, { useState } from 'react';
import './component-css/Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleAboutUsClick = (e) => {
    e.preventDefault();
    window.open('/aboutus', '_self');
  };

  const handleHomeClick = (e) => {
    e.preventDefault();
    window.open('/', '_self');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="left-items">
        <Link to="/"><img src="/images/logo1.png" alt="Logo" className="logo-image" /></Link>
      </div>
      <div className={`menu-icon ${isMobileMenuOpen ? 'active' : ''}`} onClick={toggleMobileMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <ul className={`navbar-nav ${isMobileMenuOpen ? 'active' : ''}`}>
        <li className="nav-item">
          <a href="/" className="nav-link" onClick={handleHomeClick}>Home</a>
        </li>
        <li className="nav-item">
          <a href="/" className="nav-link" onClick={handleAboutUsClick}>About Us</a>
        </li>
        <li className="nav-item">
          <a href="https://community.1future-platform.com/" target="_self" className="nav-link">Community</a>
        </li>
        <li className="nav-item">
          <a href="https://1future.feut.edu.al/" target="_blank" className="nav-link">Project Website</a>
        </li>

        {/* Learning Materials Dropdown */}
        <li className="nav-item dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
          <span className="nav-link dropdown-toggle">Learning Materials</span>
          {isDropdownOpen && (
            <ul className="dropdown-menu">
              <li><Link to="/underConstruction" className="no-underline"><a href="/learning-materials/risk-management" target="_self" rel="noopener noreferrer" className="dropdown-item">Risk Management</a></Link></li>
              <li><Link to="/underConstruction" className="no-underline"><a href="/learning-materials/energy-efficiency" className="dropdown-item">Energy Efficiency</a></Link></li>
              <li><Link to="/underConstruction" className="no-underline"><a href="/learning-materials/urban-resilience" className="dropdown-item">Urban Resilience</a></Link></li>
              <li><Link to="/underConstruction" className="no-underline"><a href="/learning-materials/sustainable-construction" className="dropdown-item">Sustainable Construction</a></Link></li>
              <li><Link to="/underConstruction" className="no-underline"><a href="/learning-materials/economics-management" className="dropdown-item">Economics and Management</a></Link></li>
            </ul>
          )}
        </li>

        <li className="nav-item">
          <Link to="/resources" className="nav-link">Tutorials</Link>
        </li>
        <li className="social-icons">
          <a href="https://www.linkedin.com/posts/1future_1future-linkedin-activity-7055539420581507072-oyYL/" target="_self" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i> 
          </a>
        </li> 
      </ul>
    </nav>
  );
};

export default Navbar;
